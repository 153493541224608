@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  transition: all linear;
}

html,
body {
  scroll-behavior: smooth;
  max-width: 100vw;
  font-family: 'Mulish', sans-serif;
}

.hero-container,
.navbar-container {
  animation: intro-scale 1s forwards;
  transform: scale(.9);
  opacity: 0;
}

.section-header {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 115%;
  align-items: center;
}

.section-sub-header {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  align-items: center;
  margin: 40px 0;
}

@keyframes intro-scale {
  from {
    transform: scale(.9);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@media only screen and (max-width: 1024px) {
  body {
    overflow-x: hidden;
  }
}