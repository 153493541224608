.Process-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 763px;
  background: #262626;
  color: #ffffff;
  overflow: hidden;
}

.popup-arrow-container {
  width: 100%;
  max-width: 100vw;
  max-height: 75%;
  height: 100%;
  position: absolute;
  top: 150px;
  left: 0;
  z-index: 1;
  pointer-events: none;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.187) 15%, rgba(41, 41, 41, 0.043) 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.popup-arrow-container .popup-text {
  font-size: 4vw;
  pointer-events: none;
  font-family: "Mulish", sans-serif;
  font-weight: 800;
}
.popup-arrow-container .arrow-popup-left {
  display: flex;
  justify-content: center;
  margin: 0 8%;
  width: 150px;
  height: 20%;
  border-radius: 50%;
}
.popup-arrow-container .arrow-popup-left img {
  max-width: 60px;
  -webkit-animation: movingL 0.5s alternate infinite ease-in-out;
          animation: movingL 0.5s alternate infinite ease-in-out;
}
.popup-arrow-container .arrow-popup-right {
  display: flex;
  justify-content: center;
  margin: 0 8%;
  width: 150px;
  height: 100%;
}
.popup-arrow-container .arrow-popup-right img {
  max-width: 60px;
  -webkit-animation: movingR 0.5s alternate infinite ease-in-out;
          animation: movingR 0.5s alternate infinite ease-in-out;
}

@-webkit-keyframes movingL {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-10%);
  }
}

@keyframes movingL {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-10%);
  }
}
@-webkit-keyframes movingR {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(10%);
  }
}
@keyframes movingR {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(10%);
  }
}
.process-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 532px;
  height: 131px;
  left: 50px;
  top: 54px;
  text-align: left;
}

.process-text-title {
  position: static;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 115%;
  color: #ffffff;
  margin: 0px 0px 40px 0px;
}

.process-text-body {
  width: 472px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
}

.process-slider-container {
  position: relative;
  width: 100%;
  top: 162px;
  height: 525px;
  margin: 0;
  max-width: 100%;
  display: flex;
  transition: all 0.4s ease-in-out;
}

.process-slider-container:hover {
  cursor: -webkit-grab;
  cursor: grab;
  transform: scale(1.02, 1.02);
}

.process-slider-item {
  position: relative;
  transition: transform 0.1s ease-in-out;
}

.first-item {
  margin-left: 150px;
}

.process-slide-mask {
  width: 333px;
  height: 450px;
  margin-right: 60px;
  border-radius: 30px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
          mask-image: -webkit-radial-gradient(white, black);
}

.process-slide-image {
  width: 100%;
  height: 550px;
  -o-object-fit: cover;
     object-fit: cover;
}

.process-slide-text {
  width: 333px;
  height: 23px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  margin-top: 16px;
  text-align: left;
}

.progress-bar-wrapper {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 5px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.7411764706);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.progress-bar {
  background: #ffffff;
  height: 5px;
  max-width: 100%;
  border-radius: 30px;
}

@media only screen and (max-width: 599px) {
  .Process-container {
    position: relative;
    width: 100%;
    min-height: unset;
  }
  .process-text-container {
    position: static;
    height: 0px;
    width: 88%;
    margin: 0 5.5% 0 5.5%;
  }
  .process-text-title {
    width: 100%;
    font-size: 30px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0px 0px 20px 0px;
  }
  .process-text-body {
    width: 100%;
    font-size: 15px;
  }
  .process-slider-container {
    position: static;
    width: 100%;
    height: 50vw;
    min-height: 510px;
    margin: 80px 0 0 0;
    max-width: 100%;
    display: flex;
  }
  .progress-bar-wrapper {
    bottom: 15%;
  }
  .process-slider-container:hover {
    cursor: -webkit-grab;
    cursor: grab;
  }
  .process-slider-item {
    margin: 20px 2.75% 0 2.75%;
  }
  .first-item {
    margin-left: 5.5%;
  }
  .process-slide-mask {
    width: 96%;
    height: 80%;
    max-height: 400px;
    margin-right: 0;
    border-radius: 30px;
    overflow: hidden;
  }
  .process-slide-image {
    width: 100%;
    min-height: 100%;
    max-height: 400px;
  }
  .process-slide-text {
    width: 333px;
    height: 23px;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    margin-top: 16px;
    text-align: left;
  }
  .popup-arrow-container {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .process-slider-item {
    margin: 20px 5% 0 5%;
  }
  .first-item {
    margin-left: 5%;
  }
  .process-slide-mask {
    width: 96%;
    height: 100%;
    max-height: 400px;
    margin-right: 0;
    border-radius: 30px;
    overflow: hidden;
  }
  .process-slide-image {
    width: 100%;
    min-height: 100%;
    max-height: 400px;
  }
  .process-slider-container {
    height: 50vw;
    min-height: 510px;
  }
  .progress-bar-wrapper {
    bottom: 15%;
  }
  .popup-arrow-container {
    display: none;
  }
}/*# sourceMappingURL=Process.css.map */