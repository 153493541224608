.coming-soon-cointainer {
  position: relative;
  max-width: 147px;
  width: 147px;
  max-height: 42px;
  box-sizing: border-box;
  text-decoration: none;
  flex-wrap: nowrap;
  margin-right: 50px;
  cursor: pointer;
  background: #ffffcc;
  border: 2px solid #ffffcc;
  border-radius: 5px;
  margin-bottom: 40px;
  color: #000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: transform 0.1s ease-in-out;
}
.coming-soon-cointainer:hover {
  -webkit-animation: scaleUp 0.5s alternate ease-in-out infinite;
          animation: scaleUp 0.5s alternate ease-in-out infinite;
}
.coming-soon-cointainer .coming-soon-text {
  white-space: nowrap;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  align-self: center;
  margin: 7.5px 7.5px 7.5px 12.5px;
  opacity: 1;
}
.coming-soon-cointainer .bell {
  opacity: 1;
  margin-right: 12.5px;
}

@-webkit-keyframes fade-content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes scaleUp {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
@keyframes scaleUp {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
@media only screen and (max-width: 599px) {
  .coming-soon-cointainer {
    width: 116px;
    margin: 0 25px 40px 0;
  }
  .coming-soon-cointainer .coming-soon-text {
    font-size: 12px;
    font-size: 0.8rem;
    margin: 7.5px 7.5px 7.5px 7.5px;
  }
  .coming-soon-cointainer .bell {
    width: 14px;
    margin-right: 5px;
    height: auto;
  }
  .hero-text-container .coming-soon-cointainer {
    width: 50vw;
    max-width: 190px;
  }
  .hero-text-container .coming-soon-text {
    font-size: 1rem;
  }
}
.features-card .coming-soon-cointainer {
  margin: 0 0 6% 6%;
  width: 61%;
  max-width: 196px;
}/*# sourceMappingURL=ComingSoon.css.map */