.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 110px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 86.98%);
  color: #ffffff;
  z-index: 2;
  pointer-events: none;
}
.navbar-container .logo-container {
  max-width: 20vw;
  height: 100%;
  display: flex;
  align-items: center;
}
.navbar-container .logo-container .logo {
  width: 151px;
  height: 60px;
  margin: 25px 0 25px 50px;
}

@-webkit-keyframes fade-content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media only screen and (max-width: 599px) {
  .navbar-container {
    position: relative;
    justify-content: space-between;
  }
  .navbar-container .logo-container {
    max-width: 50vw;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .navbar-container .logo-container .logo {
    margin: 25px 0 25px 25px;
  }
}/*# sourceMappingURL=Header.css.map */