.ipad-preview-container {
  position: relative;
}
.ipad-preview-container .ipad-preview-mask {
  width: 100%;
  height: 95vh;
  max-height: 700px;
  overflow: hidden;
}
.ipad-preview-container .ipad-preview-mask .ipad-preview-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ipad-preview-container .ipad-preview-card {
  position: absolute;
  top: initial;
  bottom: -50px;
  left: 50px;
  height: 350px;
  z-index: 1;
}
.ipad-preview-container .ipad-preview-card .card-header {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.ipad-preview-container .section-four-background {
  height: 675px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media only screen and (max-width: 599px) {
  .ipad-preview-container {
    background: #262626;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .ipad-preview-container .ipad-preview-mask {
    height: 100%;
    min-height: 100%;
    max-height: 60vh;
    width: 100%;
    margin: 0;
  }
  .ipad-preview-container .ipad-preview-mask .ipad-preview-image {
    width: 100%;
    height: 100%;
  }
  .ipad-preview-container .ipad-preview-card {
    position: relative;
    padding: 0;
    top: -5vh;
    bottom: initial;
    left: 5.5%;
    width: 89%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-family: "Mulish", sans-serif;
    font-style: normal;
  }
  .ipad-preview-container .ipad-preview-card .card-header {
    position: static;
    width: 87.5%;
    margin: 6.25%;
    font-weight: 800;
    font-size: 30px;
    line-height: 115%;
  }
  .ipad-preview-container .ipad-preview-card .card-sub-header {
    width: 87.5%;
    margin: 6.25%;
    height: 100%;
    font-weight: bold;
    font-size: 20px;
    line-height: 110%;
  }
  .ipad-preview-container .ipad-preview-card .card-text {
    width: 87.5%;
    margin: 6.25%;
    height: 100%;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .ipad-preview-container {
    background: #262626;
  }
  .ipad-preview-container .ipad-preview-mask {
    width: 100%;
    height: 100%;
    max-height: 550px;
    overflow: hidden;
  }
  .ipad-preview-container .ipad-preview-mask .ipad-preview-image {
    width: 100%;
    height: 100%;
  }
  .ipad-preview-container .ipad-preview-card {
    position: relative;
    padding: 0;
    top: -50px;
    bottom: 0;
    left: 5%;
    right: 5%;
    width: 89%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-family: "Mulish", sans-serif;
    font-style: normal;
  }
  .ipad-preview-container .ipad-preview-card .card-header {
    position: static;
    width: 87.5%;
    margin: 20px 6.25%;
    font-weight: 800;
    font-size: 30px;
    line-height: 115%;
  }
  .ipad-preview-container .ipad-preview-card .card-sub-header {
    width: 87.5%;
    margin: 20px 6.25%;
    height: 100%;
    font-weight: bold;
    font-size: 20px;
    line-height: 110%;
  }
  .ipad-preview-container .ipad-preview-card .card-text {
    width: 87.5%;
    margin: 20px 6.25%;
    height: 100%;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
  }
}/*# sourceMappingURL=IpadPreview.css.map */