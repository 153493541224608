.advantages-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .phones-tower-image-mask {
    height: 98vh;
    max-height: 700px;
    min-height: 600px;
    overflow: hidden;
    .phones-and-tower-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .join-smile{
    width: 40px;
  }

  .advantages-lower-container {
    position: relative;
    width: 100%;
    height: 500px;
    background-color: #262626;

    .advantage-card {
      left: 50px;
      top: -100px;
      width: 38%;
      height: 390px;
      ul {
        list-style: none;
        .advantage-item,
        .advantage-item-number {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: bold;
          line-height: 110%;
          color: #ffeecc;
          margin: 0 0 40px 0;
        }
        .advantage-item {
          font-size: 20px;
        }
        .advantage-item-number {
          font-size: 25px;
        }
      }

      .advantage-card-header {
        font-family: 'Mulish', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 115%;
      }

      .advantage-card-sub-header {
        font-family: 'Mulish', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 110%;
        margin: 40px 0px 20px 0px;
      }
    }

    .join-container {
      position: absolute;
      right: 50px;
      top: 26%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      width: 42%;
      .join-bell {
        width: 50px;
        height: 50px;
      }
      .join-header {
        font-family: 'Mulish', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 38px;
        color: #ffffcc;
        margin: 10px 0;
      }
      .join-text {
        font-family: 'Mulish', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        color: #ffffff;
        margin: 0 0 10px 0;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .advantages-container {
    width: 100%;
    height: 100%;
    overflow: visible;

    .phones-tower-image-mask {
      height: 56vh;
      max-height: 633px;
      min-height: 360px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .phones-and-tower-image {
        width: 110vw;
        height: 100%;
        min-height: 500px;
      }
    }

    .advantages-lower-container {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #262626;

      .advantage-card,
      .features-card {
        position: relative;
        width: 78%;
        top: -7vh;
        left: 0;
        right: 0;
        height: 100%;

        margin: 0 5.5%;
        padding: 5.5%;

        ul {
          list-style: none;
          .advantage-item,
          .advantage-item-number {
            font-family: 'Mulish', sans-serif;
            font-style: normal;
            font-weight: bold;
            line-height: 110%;
            color: #ffeecc;
            margin: 10px 0px;
          }
          .advantage-item {
            font-size: 20px;
          }
          .advantage-item-number {
            font-size: 25px;
          }
        }

        .advantage-card-header {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 30px;
          line-height: 115%;
        }

        .advantage-card-sub-header {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 110%;
          margin: 40px 0px;
        }
      }

      .join-container {
        position: static;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 0 20px 0;
        margin: 0 5.5%;
        width: 90%;
        text-align: center;
        .join-bell {
          width: 37px;
          height: 41px;
        }
        .join-header {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 20px;
          line-height: 38px;
          color: #ffffcc;
          margin: 10px 0;
        }
        .join-text {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 19px;
          color: #ffffff;
          margin: 0 0 10px 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .advantages-container {
    width: 100%;
    height: 100%;
    overflow: visible;

    .phones-tower-image-mask {
      height: 56vh;
      max-height: 633px;
      min-height: 360px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .phones-and-tower-image {
        width: 110vw;
        height: 100%;
        min-height: 500px;
      }
    }

    .advantages-lower-container {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #262626;

      .advantage-card,
      .features-card {
        position: relative;
        width: 78%;
        top: -7vh;
        left: 0;
        right: 0;
        height: 100%;

        margin: 0 5.5%;
        padding: 5.5%;

        ul {
          list-style: none;
          .advantage-item,
          .advantage-item-number {
            font-family: 'Mulish', sans-serif;
            font-style: normal;
            font-weight: bold;
            line-height: 110%;
            color: #ffeecc;
            margin: 10px 0px;
          }
          .advantage-item {
            font-size: 20px;
          }
          .advantage-item-number {
            font-size: 25px;
          }
        }

        .advantage-card-header {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 30px;
          line-height: 115%;
        }

        .advantage-card-sub-header {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 110%;
          margin: 40px 0px;
        }
      }

      .join-container {
        position: static;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 0 20px 0;
        margin: 0 5.5%;
        width: 90%;
        text-align: center;
        .join-bell {
          width: 37px;
          height: 41px;
        }
        .join-header {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 20px;
          line-height: 38px;
          color: #ffffcc;
          margin: 10px 0;
        }
        .join-text {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 19px;
          color: #ffffff;
          margin: 0 0 10px 0;
        }
      }
    }
  }
}
