.prototype-container {
  margin: 0;
  padding: 0 0 0% 0;
  height: 900px;
  background: #262626;
  color: #ffffff;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.prototype-container .prototype-text-container {
  position: absolute;
  top: 24px;
  left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  width: 472px;
  height: 80%;
  color: #ffffff;
  font-family: "Mulish", sans-serif;
  font-style: normal;
}
.prototype-container .prototype-text-container .prototype-header {
  font-weight: 800;
  font-size: 30px;
  line-height: 115%;
  margin: 0px 0px 40px 0;
}
.prototype-container .prototype-text-container .prototype-body-title {
  position: absolute;
  height: 22px;
  left: calc(50% - 236px);
  top: 200px;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  width: 100%;
}
.prototype-container .prototype-text-container .prototype-body {
  position: absolute;
  width: 472px;
  height: 76px;
  left: calc(50% - 236px);
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
}
.prototype-container .prototype-text-container .prototype-body .prototype-icon {
  width: 36px;
  margin-right: 24px;
}
.prototype-container .prototype-text-container .prototype-body .prototype-halign {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.prototype-container .prototype-images {
  position: relative;
  right: 0px;
  top: 0;
  height: 100%;
  width: 100%;
}
.prototype-container .prototype-images .smaller-img {
  position: absolute;
  width: 22%;
  height: 512px;
  right: 35%;
  top: 150px;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.25));
  border-radius: 30px;
  -o-object-fit: cover;
     object-fit: cover;
}
.prototype-container .prototype-images .girls-mask {
  position: absolute;
  max-width: 556px;
  height: 792px;
  right: 9px;
  top: 56px;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.25));
  border-radius: 30px;
  overflow: hidden;
  z-index: -1;
}
.prototype-container .prototype-images .girls-mask .girls-image {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  max-width: 553px;
  height: 1000px;
}

@media only screen and (max-width: 599px) {
  .prototype-container {
    height: 100%;
    margin: 0;
  }
  .prototype-container .prototype-text-container {
    position: static;
    margin: 0 5.5%;
    height: 100%;
    width: 90%;
    max-width: 599px;
  }
  .prototype-container .prototype-text-container .prototype-header {
    font-size: 30px;
    width: 100%;
    white-space: initial;
    position: static;
    line-height: 115%;
    margin: 0 0 40px 0px;
  }
  .prototype-container .prototype-text-container .prototype-body-title {
    position: static;
    margin: 0;
    width: 100%;
    font-size: 20px;
    white-space: initial;
    position: static;
    margin: 0 0 40px 0px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .prototype-container .prototype-text-container .prototype-body {
    position: static;
    margin: 0 0 40px 0;
    font-size: 15px;
    width: 100%;
    height: 100%;
  }
  .prototype-container .prototype-images {
    position: static;
    margin: 0 5.5%;
    max-width: 90%;
    height: 100%;
  }
  .prototype-container .prototype-images .girls-mask {
    position: absolute;
    max-width: 553px;
    height: 792px;
    right: 50px;
    top: 56px;
    filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.25));
    border-radius: 30px;
    overflow: hidden;
    z-index: -1;
  }
  .prototype-container .prototype-images .girls-mask .girls-image {
    display: none;
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    max-width: 553px;
    height: 1000px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1239px) {
  .prototype-container {
    height: 100%;
    width: 100%;
    padding: 0 0 10% 0;
  }
  .prototype-container .prototype-text-container {
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    width: 83%;
    max-width: 1024px;
    margin: 0px 50px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .prototype-container .prototype-text-container .prototype-body-title {
    position: static;
    margin: 0;
    width: 100%;
  }
  .prototype-container .prototype-text-container .prototype-body {
    position: static;
    margin: 40px 0;
    width: 100%;
    font-size: 15px;
  }
  .prototype-container .prototype-images {
    position: relative;
    right: -30px;
    top: 26px;
    min-height: 556px;
    max-height: 556px;
    height: 100%;
    width: 100%;
  }
  .prototype-container .prototype-images .smaller-img {
    position: absolute;
    width: 50%;
    max-width: 324px;
    height: 420px;
    top: 278px;
    right: 63.5%;
    transform: translateY(-50%);
    border-radius: 30px;
  }
  .prototype-container .prototype-images .girls-mask {
    position: absolute;
    width: 53%;
    min-width: 446px;
    max-width: 359px;
    height: 556px;
    left: 38.5%;
    top: 0;
    border-radius: 30px;
  }
  .prototype-container .prototype-images .girls-mask .girls-image {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    max-width: 670px;
    height: 556px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 750px) {
  .smaller-img {
    left: -20px;
  }
}/*# sourceMappingURL=Prototype.css.map */