.hero-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .hero-banner {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    object-fit: cover;
    transition: height 0.66s ease-out;
  }

  .hero-text-container {
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    padding: 0px;
    position: absolute;
    width: 480px;
    height: 363px;
    left: 50px;
    top: 230px;
    text-align: left;
    transition: linear;
    color: #ffffff;
    font-style: normal;

    .big-text {
      font-weight: 300;
      font-size: 80px;
      line-height: 101%;
      text-align: left;
    }

    .small-text {
      width: 370px;
      /* height: 16px; */
      left: 0px;
      top: 251px;
      font-weight: 800;
      font-size: 16px;
      line-height: 25px;
      margin: 30px 0px;
    }
  }
}

.hero-container::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 15%;
  background: linear-gradient(180deg, rgba(38, 38, 38, 0) 8.33%, #262626 84.9%);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}

// breakpoint for mobile devices
@media only screen and (max-width: 599px) {
  .hero-container {
    height: 100%;

    .hero-text-container {
      top: 5%;
      left: 25px;
      width: 70%;
      max-width: 300px;
      height: 100%;

      .big-text {
        width: 100%;
        font-size: 50px;
      }

      .small-text {
        font-size: 15px;
        margin: 30px 0;
        white-space: initial;
        width: 320px;
      }
    }
  }

  .hero-container::after {
    height: 25%;
  }
}

// breakpoint for ipad users
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .hero-container {
    .hero-text-container {
      position: absolute;
      left: 50px;
      top: 25%;
      width: 75%;
      max-width: 323px;
      height: 100%;

      .big-text {
        width: 100%;
        height: auto;
        font-size: 60px;
      }

      .small-text {
        font-size: 16px;
        margin: 30px 0 30px 0;
      }
    }
  }

  .hero-container::after {
    height: 35%;
    width: 100%;
  }
}