.plate-container {
  position: relative;
  height: 150vh;
  max-height: 778px;
  background-color: #262626;
  z-index: 0;
  max-width: 100%;
  overflow: hidden;

  .plate-image-mask {
    position: absolute;
    width: 70%;
    height: 778px;
    right: -145px;
    top: 197px;

    .plate-image {
      width: 100%;
      height: 725px;
      object-fit: cover;
    }
  }

  .plate-image-inner-mask {
    position: absolute;
    top: 0;
    right: 34%;
    width: 300px;
    height: 70%;
    z-index: 1;
    overflow: visible;

    .plate-image-inner {
      position: absolute;
      right: 0;
      top: 130px;
      //  do porcentage
      // width: calc(233px + 50px);
      width: 94%;
      //
      height: auto;
      z-index: 3;
    }
  }
  .plate-text-container {
    position: absolute;
    top: 238px;
    left: 50px;
    width: 500px;
    color: #ffffff;
    z-index: 1;
    text-align: left;
    .plate-text-header {
      width: 480px;
      font-family: 'Mulish', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 30px;
      line-height: 115%;
      margin: 0 0 40px 0;
    }

    .plate-text-sub-header {
      position: static;
      width: 503px;
      font-family: 'Mulish', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 110%;
      margin: 0 0 40px 0;
    }

    .plate-text {
      width: 503px;
      font-family: 'Mulish', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;
      /* Inside Auto Layout */
      margin: 0;
    }
  }
}

// Notes for response design
// 360 > 599 = Smartphone
// 600 > 1024 = Tablet
// 1025 > above = Desk

@media only screen and (max-width: 599px) {
  .plate-container {
    position: relative;
    background-color: #262626;
    z-index: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 650px;

    .plate-image-mask {
      position: relative;
      width: 100%;
      height: 100%;
      right: 0;
      top: -25vw;
      // bottom: 50vw;
      overflow: hidden;
      z-index: -1;
      max-height: 603px;

      .plate-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .plate-image-inner-mask {
      display: none;
      .plate-image-inner {
        display: none;
      }
    }

    .plate-text-container {
      position: static;
      width: 89%;
      margin: 0 5.55%;
      font-family: 'Mulish', sans-serif;
      font-style: normal;
      overflow: visible;

      .plate-text-header {
        width: 100%;

        font-weight: 800;
        font-size: 30px;
        line-height: 115%;
        margin: 0 0 40px 0;
      }

      .plate-text-sub-header {
        position: static;
        width: 100%;
        font-weight: bold;
        font-size: 20px;
        line-height: 110%;
        margin: 0 0 40px 0;
      }

      .plate-text {
        width: 100%;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        /* Inside Auto Layout */
        margin: 0;
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .plate-container {
    position: relative;
    height: 100%;
    background-color: #262626;
    z-index: 0;
    max-width: 100%;
    max-height: 650px;
    margin: 0;
    .plate-image-mask {
      position: relative;
      width: 85vw;
      height: 100%;
      right: initial;
      left: 17%;
      top: initial;
      bottom: 15vw;
      overflow: hidden;
      z-index: -1;

      .plate-image {
        position: static;
        width: 100%;
        height: 75vw;
        margin-top: -5vw;
        object-fit: cover;
      }
    }

    .plate-image-inner-mask {
      display: none;
      .plate-image-inner {
        display: none;
      }
    }

    .plate-text-container {
      position: static;
      width: 83.3%;
      margin: 0 8%;
      font-family: 'Mulish', sans-serif;
      font-style: normal;
      overflow: visible;

      .plate-text-header {
        width: 90%;

        font-weight: 800;
        font-size: 30px;
        line-height: 115%;
        margin: 0 0 40px 0;
      }

      .plate-text-sub-header {
        position: static;
        width: 90%;
        font-weight: bold;
        font-size: 20px;
        line-height: 110%;
        margin: 0 0 40px 0;
      }

      .plate-text {
        width: 100%;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        /* Inside Auto Layout */
        margin: 0;
      }
    }
  }
}

@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .plate-container {
    max-height: 750px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1239px) {
  .plate-container {
    .plate-image-inner-mask {
      right: 18%;
    }
  }
}
