.feature-device-container {
  // width: 100%;
  // height: 100%;
  // max-height: 100%;
  position: relative;
  width: 100%;
  height: 900px;
  background-color: #262626;

  .devices-container {
    position: relative;
    width: 52%;
    height: 50%;
    top: 28%;
    left: 50px;

    .s-five-ipad {
      position: absolute;
      width: 90%;
      height: auto;
      left: 8%;
      bottom: 20px;
      mix-blend-mode: normal;
    }

    .s-five-phone {
      position: absolute;
      width: 25%;
      height: auto;
      bottom: 10px;
      mix-blend-mode: normal;
      filter: drop-shadow(5px -5px 20px rgba(0, 0, 0, 0.2));
    }

    .s-five-tower {
      position: absolute;
      width: 30%;
      height: auto;
      left: 21%;
      bottom: -20px;

      z-index: 2;
      mix-blend-mode: normal;
      filter: drop-shadow(5px -5px 20px rgba(0, 0, 0, 0.2));
    }
  }

  .feature-text-container {
    position: absolute;
    color: #ffffff;
    z-index: 1;
    text-align: left;
    right: 50px;
    top: 262px;
    left: initial;
    width: 100%;
    max-width: 500px;
    font-family: 'Mulish', sans-serif;
    font-style: normal;

    .feature-body {

      margin-top: 44px;

      .feature-icon {
        width: 36px;
        margin-right: 24px;
      }
  
      .feature-halign {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }
    }

    .feature-text-header {
      width: 100%;
      font-weight: 800;
      font-size: 30px;
      line-height: 115%;
      margin: 0 0 40px 0;
    }

    .feature-text-sub-header {
      position: static;
      width: 100%;
      font-weight: bold;
      font-size: 20px;
      line-height: 110%;
      margin: 0 0 40px 0;
    }

    .feature-text {
      width: 100%;
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;
      /* Inside Auto Layout */
      margin: 0;


    }

  }
}

@media only screen and (max-width: 599px) {
  .feature-device-container {
    height: 100%;
    margin: 0;
    padding: 5vh 0;

    .devices-container {
      position: relative;
      left: 10px;
      margin: 0 5% 40px 5%;
      min-height: 280px;
      transform: translateY(0%);
      height: 100%;
      width: 85%;

      .s-five-ipad {
        position: absolute;
        bottom: 0;
        width: 80vw;
      }

      .s-five-phone {
        position: absolute;
        bottom: 0;
        width: 20vw;
        margin: 0;
      }

      .s-five-tower {
        position: absolute;
        margin: 0;
      }
    }

    .feature-text-container {
      position: static;
      width: 90%;
      height: 50%;
      transform: translateY(0%);
      margin: 40px 5.5%;
      padding: 0 0 40px 0;

      .feature-text-header {
        width: 100%;
        font-size: 25px;
        margin: 0 0 20px 0;
      }

      .feature-text-sub-header {
        width: 100%;
        margin: 0 0 20px 0;
        font-size: 19px;
      }

      .feature-text {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .feature-device-container {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    margin: 0;
    padding: 10vh 0;

    .devices-container {
      position: relative;
      left: 10px;
      margin: 0 5% 40px 5%;
      min-height: 280px;
      height: 100%;
      width: 85%;

      .s-five-ipad {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 80vw;
        max-width: 600px;
      }

      .s-five-phone {
        position: absolute;
        bottom: 0;
        width: 20vw;
        max-width: 180px;
        max-height: 350px;
        margin: 0;
      }

      .s-five-tower {
        position: absolute;
        margin: 0;
      }
    }

    .feature-text-container {
      position: static;
      width: 80%;
      min-width: 80vw;
      height: 50%;
      margin: 40px 7.5%;
      padding: 0 0 40px 0;

      .feature-text-header {
        width: 100%;
        font-size: 25px;
        margin: 0 0 20px 0;
      }

      .feature-text-sub-header {
        width: 100%;
        margin: 0 0 20px 0;
        font-size: 19px;
      }

      .feature-text {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .feature-device-container {
    padding: 3vw 0;

    .devices-container {
      position: relative;
      left: 10px;
      margin: 0 5% 40px 5%;
      min-height: 400px;
      height: 100%;
      width: 88%;

      .s-five-ipad {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 80vw;
        max-width: 600px;
      }

      .s-five-phone {
        position: absolute;
        bottom: 0;
        width: auto;
        max-width: 180px;
        max-height: 320px;
        margin: 0 0 0 10%;
      }

      .s-five-tower {
        position: absolute;
        margin: 0;
        width: 22%;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1239px) {
  .feature-device-container {
    .devices-container {
      top: 25%;
    }

    .feature-text-container {
      position: absolute;
      color: #ffffff;
      z-index: 1;
      text-align: left;
      right: 50px;
      top: 262px;
      left: initial;
      width: 50%;
      max-width: 400px;
      font-family: 'Mulish', sans-serif;
      font-style: normal;

      .feature-text-header {
        width: 100%;
        font-weight: 800;
        font-size: 30px;
        line-height: 115%;
        margin: 0 0 40px 0;
      }

      .feature-text-sub-header {
        position: static;
        width: 100%;
        font-weight: bold;
        font-size: 20px;
        line-height: 110%;
        margin: 0 0 40px 0;
      }

      .feature-text {
        width: 100%;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        /* Inside Auto Layout */
        margin: 0;
      }
    }
  }
}