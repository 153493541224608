.controls-container {
  display: none;
}

@media only screen and (max-width: 599px) {
  .controls-container {
    position: relative;
    // bottom:0;
    // left: 0;
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;

    .left-circle-arrow {
      position: absolute;
      left: 27.7%;

      &:hover {
        cursor: pointer;
      }
    }
    .right-circle-arrow {
      position: absolute;
      right: 27.7%;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .controls-container {
    position: relative;
    bottom: -200px;
    left: 0;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .left-circle-arrow {
      position: absolute;
      left: 19%;
    }
    .right-circle-arrow {
      position: absolute;
      right: 19%;
    }
  }
}
