.plate-container {
  position: relative;
  height: 150vh;
  max-height: 778px;
  background-color: #262626;
  z-index: 0;
  max-width: 100%;
  overflow: hidden;
}
.plate-container .plate-image-mask {
  position: absolute;
  width: 70%;
  height: 778px;
  right: -145px;
  top: 197px;
}
.plate-container .plate-image-mask .plate-image {
  width: 100%;
  height: 725px;
  -o-object-fit: scale-down;
     object-fit: scale-down;
}
.plate-container .plate-image-inner-mask {
  position: absolute;
  top: 0;
  right: 34%;
  width: 300px;
  height: 70%;
  z-index: 1;
  overflow: visible;
}
.plate-container .plate-image-inner-mask .plate-image-inner {
  position: absolute;
  right: 0;
  top: 130px;
  width: 94%;
  height: auto;
  z-index: 3;
}
.plate-container .plate-text-container {
  position: absolute;
  top: 238px;
  left: 50px;
  width: 500px;
  color: #ffffff;
  z-index: 1;
  text-align: left;
}
.plate-container .plate-text-container .plate-text-header {
  width: 480px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 115%;
  margin: 0 0 40px 0;
}
.plate-container .plate-text-container .plate-text-sub-header {
  position: static;
  width: 503px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
  margin: 0 0 40px 0;
}
.plate-container .plate-text-container .plate-text {
  width: 503px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  /* Inside Auto Layout */
  margin: 0;
}

@media only screen and (max-width: 599px) {
  .plate-container {
    position: relative;
    background-color: #262626;
    z-index: 0;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .plate-container .plate-image-mask {
    position: relative;
    width: 100%;
    height: 100%;
    right: 0;
    top: 4px;
    overflow: hidden;
    z-index: -1;
    max-height: 603px;
  }
  .plate-container .plate-image-mask .plate-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .plate-container .plate-image-inner-mask {
    display: none;
  }
  .plate-container .plate-image-inner-mask .plate-image-inner {
    display: none;
  }
  .plate-container .plate-text-container {
    position: static;
    width: 89%;
    margin: 0 5.55%;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    overflow: visible;
  }
  .plate-container .plate-text-container .plate-text-header {
    width: 100%;
    font-weight: 800;
    font-size: 30px;
    line-height: 115%;
    margin: 0 0 40px 0;
  }
  .plate-container .plate-text-container .plate-text-sub-header {
    position: static;
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    line-height: 110%;
    margin: 0 0 40px 0;
  }
  .plate-container .plate-text-container .plate-text {
    width: 100%;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    /* Inside Auto Layout */
    margin: 0;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .plate-container {
    position: relative;
    height: 100%;
    background-color: #262626;
    z-index: 0;
    max-width: 100%;
    height: 100%;
    margin: 0;
  }
  .plate-container .plate-image-mask {
    position: relative;
    width: 100%;
    height: 100%;
    right: initial;
    top: 4px;
    overflow: hidden;
    z-index: -1;
  }
  .plate-container .plate-image-mask .plate-image {
    position: static;
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .plate-container .plate-image-inner-mask {
    display: none;
  }
  .plate-container .plate-image-inner-mask .plate-image-inner {
    display: none;
  }
  .plate-container .plate-text-container {
    position: static;
    width: 83.3%;
    margin: 0 8%;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    overflow: visible;
  }
  .plate-container .plate-text-container .plate-text-header {
    width: 90%;
    font-weight: 800;
    font-size: 30px;
    line-height: 115%;
    margin: 0 0 40px 0;
  }
  .plate-container .plate-text-container .plate-text-sub-header {
    position: static;
    width: 90%;
    font-weight: bold;
    font-size: 20px;
    line-height: 110%;
    margin: 0 0 40px 0;
  }
  .plate-container .plate-text-container .plate-text {
    width: 100%;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    /* Inside Auto Layout */
    margin: 0;
  }
}
@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .plate-container {
    max-height: 580px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1239px) {
  .plate-container .plate-image-inner-mask {
    right: 18%;
  }
}/*# sourceMappingURL=Plate.css.map */