.design-container {
  position: relative;
  height: 680px;
  background: #ededed;

  .hand-phone-img-mask {
    position: absolute;
    width: 80%;
    height: 680px;
    left: 0px;
    bottom: 0px;
    overflow: hidden;
  }

  .hand-phone-img {
    width: 100%;
    height: 720px;
    object-fit: cover;
  }
}

.features-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 100px 50px;

  position: absolute;
  width: fit-content;
  // height: 578px;
  bottom: -50px;
  right: 50px;

  background: #000000;
  border-radius: 30px;
  color: #ffffff;
  z-index: 1;

  .card-header {
    position: static;
    width: 437px;
    min-height: 34px;
    height: fit-content;
    margin: 0;
  }

  .card-sub-header {
    width: 400px;
    height: 66px;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 110%;
    margin: 40px 0 0 0;
  }

  .card-text {
    width: 437px;
    height: 114px;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    margin: 40px 0 40px 0;
  }
}

@media only screen and (max-width: 599px) {
  .design-container {
    position: relative;
    height: 100%;
    width: 100%;
    background: #262626;

    .hand-phone-img-mask {
      position: static;
      width: 100%;
      height: 100%;

      .hand-phone-img {
        width: 125vw;
        min-width: 500px;
        height: 100%;
      }
    }

    .features-card {
      position: relative;
      padding: 0;
      top: -8vw;
      bottom: initial;
      left: 5.5%;
      width: 89%;
      height: fit-content;
      // margin: -5.5% 5.5% 0 5.5%;

      font-family: 'Mulish', sans-serif;
      font-style: normal;

      .card-header {
        position: static;
        width: 87.5%;
        margin: 6.25%;
        font-weight: 800;
        font-size: 30px;
        line-height: 115%;
      }

      .card-sub-header {
        width: 87.5%;
        margin: 6.25%;
        height: 100%;
        font-weight: bold;
        font-size: 20px;
        line-height: 110%;
      }

      .card-text {
        width: 87.5%;
        margin: 6.25%;
        height: 100%;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
      }
    }
  }
}

// breakpoint for ipad users
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .design-container {
    position: relative;
    background: #262626;
    height: 100%;

    .hand-phone-img-mask {
      position: static;
      width: 100%;
      height: 100%;

      .hand-phone-img {
        width: 125vw;
        min-width: 500px;
        height: 100%;
      }
    }

    .features-card {
      position: relative;
      padding: 0;
      top: initial;
      bottom: 50px;
      left: 5%;
      right: 5%;
      width: 89%;
      height: fit-content;
      // margin: -5.5% 5.5% 0 5.5%;

      font-family: 'Mulish', sans-serif;
      font-style: normal;

      .card-header {
        position: static;
        width: 87.5%;
        margin: 20px 6.25%;
        font-weight: 800;
        font-size: 30px;
        line-height: 115%;
      }

      .card-sub-header {
        width: 87.5%;
        margin: 20px 6.25%;
        height: 100%;
        font-weight: bold;
        font-size: 20px;
        line-height: 110%;
      }

      .card-text {
        width: 87.5%;
        margin: 20px 6.25%;
        height: 100%;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
      }
    }
  }
}
