.controls-container {
  display: none;
}

@media only screen and (max-width: 599px) {
  .controls-container {
    position: relative;
    width: 100%;
    height: 70px;
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
  }

  .controls-container .left-circle-arrow {
    position: absolute;
    left: 27.7%;
  }

  .controls-container .left-circle-arrow:hover {
    cursor: pointer;
  }

  .controls-container .right-circle-arrow {
    position: absolute;
    right: 27.7%;
  }

  .controls-container .right-circle-arrow:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .controls-container {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .controls-container .left-circle-arrow {
    position: absolute;
    left: 19%;
  }

  .controls-container .right-circle-arrow {
    position: absolute;
    right: 19%;
  }
}

/*# sourceMappingURL=ArrowScroll.css.map */