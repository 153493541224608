// fill up animation
$intro-fill: intro-fill-up 2s 2s 1 forwards;
// image animation circle
$loading-in: fade-in 1.5s 4s forwards;
// fade the logo text
$fade-logo: fade-logo 2s 5.5s forwards;
// fade out all
$fade-out: fade-out 1s 5.5s 1 forwards;

body {
  background-color: #000000;
}

.intro-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #272626;
  z-index: 1;

  animation: $fade-out;
}

.intro-background {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 1;
  overflow: hidden;
  opacity: 1;
  animation: $intro-fill;
  transition: all linear;
  clip-path: polygon(
    31% 36%,
    63% 19%,
    100% 30%,
    100% 80%,
    100% 100%,
    0 100%,
    0% 80%,
    0 33%
  );
}

@keyframes intro-fill-up {
  from {
    top: 100%;
    clip-path: polygon(
      31% 36%,
      63% 19%,
      100% 30%,
      100% 80%,
      100% 100%,
      0 100%,
      0% 80%,
      0 33%
    );
  }
  to {
    top: 0;
    clip-path: polygon(
      19% 0,
      60% 0,
      100% 0,
      100% 80%,
      100% 100%,
      0 100%,
      0% 80%,
      0 0
    );
  }
}

.loading {
  width: 100%;
  height: 100%;

  object-fit: cover;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0;
  clip-path: circle(1% at 50% 50%);

  animation: $loading-in;
  transition: all ease;
}

.logo-text {
  z-index: 6;
  position: absolute;
  width: 188.16px;
  height: 44.1px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: $loading-in, $fade-logo;
  transition: all ease;
  opacity: 0;
}

@keyframes fade-logo {
  to {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    clip-path: circle(1% at 50% 50%);
  }
  to {
    opacity: 0.8;
    clip-path: circle(100% at 50% 50%);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
