.opportunities-container {
  height: 602px;
  position: relative;
  overflow: hidden;
}
.opportunities-container::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(38, 38, 38, 0.65);
  z-index: 1;
}
.opportunities-container .pattern-mask {
  height: 602px;
  overflow: hidden;
}
.opportunities-container .pattern-mask .pattern {
  width: 100%;
  height: 1000px;
  -o-object-fit: cover;
     object-fit: cover;
}
.opportunities-container .opportunities-text-container {
  position: absolute;
  width: 36%;
  left: 31.5%;
  top: 21.6%;
  color: #ffffff;
  z-index: 2;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.opportunities-container .opportunities-text-container .opportunities-text-header {
  width: 100%;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 115%;
  margin: 0;
}
.opportunities-container .opportunities-text-container .opportunities-text-subheader {
  width: 100%;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
  margin: 40px 0px;
}
.opportunities-container .opportunities-text-container .opportunities-text {
  width: 100%;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
}
.opportunities-container .opportunities-text-container .contact-button {
  width: 418px;
  height: 54px;
  margin: 40px auto;
}

@media only screen and (max-width: 1024px) {
  .opportunities-container {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .opportunities-container .pattern-mask {
    height: 90%;
    max-height: 400px;
    overflow: hidden;
  }
  .opportunities-container .pattern-mask .pattern {
    width: 100%;
    height: 120vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .opportunities-container .opportunities-text-container {
    width: 88%;
    height: 90%;
    left: 5.5%;
    top: 5.5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .opportunities-container .opportunities-text-container .opportunities-text-header {
    width: 100%;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 115%;
    margin: 0;
  }
  .opportunities-container .opportunities-text-container .opportunities-text-subheader {
    width: 100%;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 110%;
    margin: 30px 0px;
  }
  .opportunities-container .opportunities-text-container .opportunities-text {
    width: 100%;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
  }
  .opportunities-container .opportunities-text-container .contact-button {
    min-width: 89%;
    max-width: 100%;
    height: auto;
    margin: 30px auto;
  }
}/*# sourceMappingURL=Opportunities.css.map */