@mixin displayFlex($direction) {
  display: flex;
  flex-direction: $direction;
  align-items: center;
}

.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 110px;
  @include displayFlex(row);
  justify-content: space-between;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 86.98%
  );
  color: #ffffff;
  z-index: 2;

  .logo-container {
    max-width: 20vw;
    height: 100%;
    display: flex;
    align-items: center;

    .logo {
      width: 151px;
      height: 60px;
      margin: 25px 0 25px 50px;
    }
  }
}

@keyframes fade-content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Notes for response design
// 360 > 599 = Smartphone
// 600 > 1024 = Tablet
// 1025 > above = Desk

// Breakpoint for mobile screens
@media only screen and (max-width: 599px) {
  .navbar-container {
    position: relative;
    justify-content: space-between;
    .logo-container {
      max-width: 50vw;
      height: 100%;
      display: flex;
      align-items: center;
      .logo {
        margin: 25px 0 25px 25px;
      }
    }
  }
}
