.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  color: white;
  overflow: auto;
  -webkit-animation: fadein 0.8s forwards;
          animation: fadein 0.8s forwards;
  transform: translateY(100%);
  transition: 0.8s ease-out;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}

@-moz-document url-prefix() {
  .modal-container::before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 120vw;
    height: 120vh;
    margin: -10px;
    background-image: url(../../../assets/haffinity-background-design.jpg);
    background-position: center;
    object-fit: cover;
  }
}
.modal-wrapper {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@-webkit-keyframes fadein {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-10%);
    transform: scale(1.02, 1.02);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes fadein {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-10%);
    transform: scale(1.02, 1.02);
  }
  100% {
    transform: translateY(0%);
  }
}
.upper-controls {
  position: absolute;
  top: 7%;
  left: 5%;
  right: 5%;
  display: flex;
  justify-content: space-between;
}

.close-icon {
  transition: 0.2s ease-in-out;
}
.close-icon:hover {
  cursor: pointer;
  transform: scale(1.2, 1.2);
}

.counter {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
  color: rgba(255, 255, 255, 0.5);
}

.logo-text-contact {
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.bullets-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  right: 5.5%;
  transform: translateY(-50%);
  z-index: 9999;
}

.bullet {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ffffff;
  margin-top: 22px;
}
.bullet:hover {
  cursor: pointer;
}

.form-stepper-wrapper {
  position: absolute;
  left: 50%;
  top: 18%;
  transform: translateX(-50%);
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 25px;
  font-size: 20px;
  height: 60%;
  width: 500px;
}

.step-label {
  margin-left: 0;
}

.special-label-position {
  margin: 0 0 0 -10%;
}

.step-small-input {
  background: #000000;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  margin: 10px 0 0 0;
  height: 50px;
  width: 100%;
  font-size: 24px;
  color: #ffffff;
  padding-left: 10px;
}
.step-small-input:focus {
  outline: none;
}

.step-message-field {
  width: 100%;
  background: #000000;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  height: 200px;
  margin: 10px 0 0 0;
  color: #ffffff;
  padding: 10px;
}
.step-message-field:focus {
  outline: none;
}

.next-btn {
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  width: 418px;
  min-height: 54px;
  background: #cceeff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  color: #000000;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 25px;
  font-size: 20px;
  z-index: 10;
}
.next-btn:hover {
  background: #bbd7e6;
  cursor: pointer;
}

@-webkit-keyframes sending {
  from {
    transform: translate(0%, 0%);
  }
  to {
    transform: translate(0%, -10%);
  }
}

@keyframes sending {
  from {
    transform: translate(0%, 0%);
  }
  to {
    transform: translate(0%, -10%);
  }
}
.small-text-step {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  transition: opacity 0.5s ease-in-out;
}

.privacy-text {
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin: 10px 0;
}

.steps {
  position: relative;
  width: 100%;
  height: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}

.step1,
.step2,
.step3,
.step4,
.step5,
.step6 {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -25%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: transform 0.6s ease-in-out, opacity 0.5s ease-in-out, visibility 0.6s ease-in-out;
  height: 100%;
  width: 100%;
  opacity: 1;
  visibility: hidden;
}

.mail-sent-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  display: flex;
  color: #ccffcc;
  flex-direction: column;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.selection-container {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 -10%;
  flex-wrap: wrap;
}

.row {
  display: flex;
}

.checkbox-item {
  visibility: hidden;
}

#checkbox-capital {
  border: 1px solid #ccffcc;
  color: #ccffcc;
}

#checkbox-get-haffinity {
  border: 1px solid #ffaacc;
  color: #ffaacc;
}

#checkbox-feedback {
  border: 1px solid #cceeff;
  color: #cceeff;
}

#checkbox-curiosity {
  border: 1px solid #ccaaee;
  color: #ccaaee;
}

#checkbox-joinus {
  border: 1px solid #ffffcc;
  color: #ffffcc;
}

#checkbox-other {
  border: 1px solid #cccccc;
  color: #cccccc;
}

#checkbox-social {
  border: 1px solid #ffffff;
  color: #ffffff;
}

#checkbox-research {
  border: 1px solid #ccaaee;
  color: #ccaaee;
}

#checkbox-word {
  border: 1px solid #ffcccc;
  color: #ffcccc;
}

#checkbox-newspaper {
  border: 1px solid #ccffff;
  color: #ccffff;
}

#checkbox-blogs {
  border: 1px solid #cceecc;
  color: #cceecc;
}

.checkbox-square {
  bottom: 0;
  background: #000000;
  border-radius: 10px;
  width: 14vw;
  min-width: 191px;
  max-width: 191px;
  height: 20vh;
  max-height: 131px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  transition: 0.5s ease-in-out;
}
.checkbox-square:hover {
  cursor: pointer;
  transform: translateY(-5%);
}

.checkbox-label {
  transform: translateY(100%);
  transition: 0.5s ease-in-out;
}
.checkbox-label:hover {
  cursor: pointer;
}

.check-icon {
  opacity: 0;
  transition: 0.4s opacity ease-in;
}

@media only screen and (max-width: 600px) {
  .upper-controls {
    top: 2%;
    left: 3%;
    width: 90%;
  }
  .close-icon {
    transform: scale(90%);
  }
  .counter {
    font-size: 30px;
  }
  .logo-text-contact {
    width: 40vw;
  }
  .bullets-container {
    display: flex;
    flex-direction: row;
    top: initial;
    right: initial;
    bottom: 4%;
    left: 50%;
    transform: translateX(-50%);
  }
  .bullet {
    margin: 5px;
  }
  .bullet:hover {
    cursor: pointer;
  }
  .form-stepper-wrapper {
    top: 25%;
    height: 50vh;
    width: 100vw;
    display: flex;
  }
  .step-label {
    margin-left: 0;
    width: 97%;
    text-align: center;
  }
  .special-label-position {
    margin: 0;
  }
  .step-small-input {
    margin: 5px;
    width: 90vw;
    padding-left: 10px;
  }
  .step-small-input:focus {
    outline: none;
  }
  .step-message-field {
    width: 90%;
    margin: 10px 5px 0 5px;
    padding: 10px;
  }
  .step-message-field:focus {
    outline: none;
  }
  .next-btn {
    width: 70vw;
    bottom: 14%;
  }
  .next-btn:hover {
    background: #bbd7e6;
    cursor: pointer;
  }
  .small-text-step {
    display: none;
  }
  .privacy-text {
    margin: 2% 5%;
  }
  .steps {
    height: 100%;
  }
  .selection-container {
    display: flex;
    flex-direction: column;
    margin: 10px auto;
    width: 96%;
    flex-wrap: wrap;
  }
  .row {
    display: flex;
    justify-content: center;
  }
  .checkbox-square {
    position: relative;
    width: 29vw;
    min-width: 80px;
    height: 15vh;
    max-height: 131px;
    margin: 5px;
    white-space: initial;
    font-size: 14px;
  }
  .checkbox-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}/*# sourceMappingURL=Modal.css.map */

.modal-container .form-stepper-wrapper {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  text-align: left;
  width: 68%;
  max-width: 491.5px;
  height: 77%;
}
.modal-container .form-stepper-wrapper .contact-input-fields {
  background: #000000;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  min-height: 44px;
  padding-left: 10px;
  margin: 12px 0 20px 0;
  min-width: 200px;
  color: #ffffff;
  transition: ease-in-out 0.1s;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}
.modal-container .form-stepper-wrapper .contact-input-fields:focus {
  box-shadow: 0px 0px 5px 3px rgba(33, 128, 219, 0.93);
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  outline: none;
}
.modal-container .form-stepper-wrapper .message-area {
  min-height: 84px;
  padding: 10px;
  resize: none;
}
.modal-container .form-stepper-wrapper .name-row {
  display: block;
  flex-direction: row;
  justify-content: space-between;
  margin: auto 0;
}
.modal-container .form-stepper-wrapper .flex-column {
  display: flex;
  flex-direction: column;
}
.modal-container .form-stepper-wrapper .contact-label {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  text-align: left;
}
.modal-container .form-stepper-wrapper .contact-button {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #000000;
  background: #ccffcc;
  border: 1px solid #ffffff;
  border-radius: 10px;
  min-height: 54px;
}
.modal-container .form-stepper-wrapper .contact-button .arrow-right {
  width: 24px;
  height: 24px;
  transition: opacity 0.5s ease-in-out;
}
.modal-container .form-stepper-wrapper .contact-button:focus {
  outline: none;
}
.modal-container .form-stepper-wrapper .contact-button:hover {
  background: #b5f8b5;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.modal-container .form-stepper-wrapper .contact-thank-you-message {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  margin-top: 10px;
}