.contact-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  overflow: hidden;
  .left-panel,
  .right-panel {
    width: 50%;
    height: 100%;
    max-height: 830px;
  }

  .left-panel {
    background: linear-gradient(214.72deg, #ccffcf 0.51%, #cceeff 99.19%);
    height: 830px;

    display: flex;
    align-items: center;

    .contact-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 75%;
      height: 80.6%;
      background: #000000;
      border-radius: 30px;
      margin: 11.7% 14.2%;
      color: #ffffff;
    }

    .contact-card-text-container {
      margin: 100px 50px;
      height: 100%;

      .contact-card-header {
        font-family: 'Mulish', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 115%;
      }

      .contact-card-list li {
        list-style: none;

        font-family: 'Mulish', sans-serif;
        font-style: normal;
        font-weight: bold;

        font-size: 20px;
        line-height: 110%;
        margin: 10px 0px;
      }
    }

    .contact-item {
      display: flex;
      margin: 5% 0;

      span {
        color: #ccffcc;
      }

      .circle-check {
        min-width: 30px;
        min-height: 30px;
        margin-right: 10px;
      }
    }
  }

  .right-panel {
    background-color: #373737;
    display: flex;
    align-items: center;

    .contact-form {
      display: flex;
      flex-direction: column;
      color: #ffffff;

      text-align: left;

      width: 68%;
      max-width: 491.5px;
      height: 77%;
      margin: 13% auto;
      .contact-input-fields {
        background: #000000;
        border: 1px solid #b3b3b3;
        border-radius: 10px;
        min-height: 44px;
        padding-left: 10px;
        margin: 12px 0 20px 0;
        min-width: 200px;
        color: #ffffff;
        transition: ease-in-out 0.1s;
        font-family: 'Mulish', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;

        &:focus {
          box-shadow: 0px 0px 5px 3px rgba(33, 128, 219, 0.93);
          border: 1px solid #b3b3b3;
          border-radius: 10px;
          outline: none;
        }
      }
      .message-area {
        min-height: 84px;
        padding: 10px;
        resize: none;
      }

      .name-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: auto 0;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
      }

      .contact-label {
        font-family: 'Mulish', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        text-align: left;
      }

      .contact-button {
        font-family: 'Mulish', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #000000;
        background: #ccffcc;
        border: 1px solid #ffffff;
        border-radius: 10px;
        min-height: 54px;
        .arrow-right {
          width: 24px;
          height: 24px;
          transition: opacity 0.5s ease-in-out;
        }

        &:focus {
          outline: none;
        }

        &:hover {
          background: #b5f8b5;
          cursor: pointer;
          transition: 0.2s ease-in-out;
        }
      }
      .contact-thank-you-message {
        font-family: 'Mulish', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        margin-top: 10px;
      }
    }
  }
}

.contact-button {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #000000;
  background: #ccffcc;
  border: 1px solid #ffffff;
  border-radius: 10px;
  min-height: 54px;
  margin-top: 20px;
  transition: transform 0.5s ease-in-out;

  .arrow-right {
    width: 24px;
    height: 24px;
  }

  @keyframes sending {
    from {
      transform: translateY(0%);
    }
    to {
      transform: translateY(-10%);
    }
  }

  &:hover {
    background: #b5f8b5;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }
}

@media only screen and (max-width: 599px) {
  .contact-container {
    position: static;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .left-panel {
      background: linear-gradient(214.72deg, #ccffcf 0.51%, #cceeff 99.19%);
      width: 100%;
      max-height: 460px;
      max-width: 100vw;
      .contact-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 89vw;
        height: fit-content;
        margin: 5.5%;
      }

      .contact-card-text-container {
        margin: 5.5%;
        height: 100%;

        .contact-card-header {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 30px;
          line-height: 115%;
        }

        .contact-card-list li {
          list-style: none;

          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: bold;

          font-size: 20px;
          line-height: 110%;
          margin: 0px;
        }
      }

      .contact-item {
        display: flex;
        margin: 5% 0;

        span {
          color: #ccffcc;
        }

        .circle-check {
          min-width: 30px;
          min-height: 30px;
          margin-right: 10px;
        }
      }
    }

    .right-panel {
      background-color: #373737;
      width: 100%;
      max-width: 100vw;
      min-height: 100%;
      max-height: 1000px;
      margin: 0;
      height: 100%;

      .contact-form {
        width: 90%;
        max-width: 90vw;
        height: 100%;
        max-height: 100%;
        margin: 5vw 5%;
        padding: 0 0 10vw 0;

        // border: 5px solid red;
        .contact-input-fields {
          transition: ease-in-out 0.1s;
        }

        .message-area {
          min-height: 84px;
        }

        .name-row {
          flex-direction: column;
        }

        .flex-column {
          display: flex;
          flex-direction: column;
        }

        .contact-label {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 19px;
          text-align: left;
        }

        .contact-button {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 25px;
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: #000000;
          background: #ccffcc;
          border: 1px solid #ffffff;
          border-radius: 10px;
          min-height: 54px;
          .arrow-right {
            width: 24px;
            height: 24px;
          }

          &:hover {
            background: #b5f8b5;
            cursor: pointer;
            transition: 0.2s ease-in-out;
          }
        }

        .contact-thank-you-message {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 19px;
          margin-top: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .contact-container {
    position: static;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .left-panel {
      background: linear-gradient(214.72deg, #ccffcf 0.51%, #cceeff 99.19%);
      width: 100%;
      max-width: 100vw;
      height: 100%;
      .contact-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 89vw;
        height: fit-content;
        margin: 5.5%;
      }

      .contact-card-text-container {
        margin: 5.5%;
        height: 100%;

        .contact-card-header {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 30px;
          line-height: 115%;
        }

        .contact-card-list li {
          list-style: none;

          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: bold;

          font-size: 20px;
          line-height: 110%;
          margin: 0px;
        }
      }

      .contact-item {
        display: flex;
        margin: 5% 0;

        span {
          color: #ccffcc;
        }

        .circle-check {
          min-width: 30px;
          min-height: 30px;
          margin-right: 10px;
        }
      }
    }

    .right-panel {
      background-color: #373737;
      width: 100%;
      max-width: 100vw;
      min-height: 100%;
      max-height: 1000px;
      margin: 0;
      height: 100%;

      .contact-form {
        width: 90%;
        max-width: 90vw;
        height: 100%;
        max-height: 100%;
        min-height: 100%;
        margin: 3vw 5.5%;
        padding: 0 0 10vw 0;
        .contact-input-fields {
          transition: ease-in-out 0.1s;
        }

        .message-area {
          min-height: 84px;
        }

        .name-row {
          flex-direction: column;
        }

        .flex-column {
          display: flex;
          flex-direction: column;
        }

        .contact-label {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 19px;
          text-align: left;
        }

        .contact-button {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 25px;
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: #000000;
          background: #ccffcc;
          border: 1px solid #ffffff;
          border-radius: 10px;
          min-height: 54px;
          .arrow-right {
            width: 24px;
            height: 24px;
          }

          &:hover {
            background: #b5f8b5;
            cursor: pointer;
            transition: 0.2s ease-in-out;
          }
        }

        .contact-thank-you-message {
          font-family: 'Mulish', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 19px;
          margin-top: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1239px) {
  .contact-container {
    overflow: hidden;
    max-height: 980px;
    .left-panel {
      min-height: 1000px;
      display: flex;
      align-items: center;
      .contact-card {
        width: 100%;
        height: fit-content;
        margin: 8%;
      }
    }
    .right-panel {
      min-height: 975px;
      max-height: 1000px;
      .contact-form {
        .name-row {
          flex-direction: column;
        }
      }
    }
  }
}
