.Footer-container {
  display: flex;
  flex-direction: row;
  color: #ffffff;
  justify-content: space-between;
  height: 101px;

  overflow: hidden;

  .left,
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .left {
    margin-left: 50px;
    img {
      margin-right: 13px;
      width: 160px;
    }
    .coming-text {
      font-family: 'Mulish', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 110%;
    }
  }

  .right {
    p {
      font-family: 'Mulish', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 110%;
      margin-right: 50px;
    }
  }
}

@media only screen and (max-width: 599px) {
  .Footer-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 5.5% 0;
    .left,
    .right {
      flex-direction: column;
      align-items: center;
    }

    .left {
      margin-left: 0;
      img {
        margin-right: 0;
        width: 44%;
      }
      .coming-text {
        font-size: 15px;
        margin: 20px 0;
      }
    }

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      p {
        width: 72vw;
        font-family: 'Mulish', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 110%;
        margin-right: 0;
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .Footer-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 5.5% 0;
    .left,
    .right {
      flex-direction: row;
      align-items: center;
    }

    .left {
      margin-left: 0;
      width: 90vw;
      display: flex;
      justify-content: center;
      img {
        margin-right: 13px;
        max-width: 160px;
        width: 44%;
      }
      .coming-text {
        font-size: 15px;
        margin: 20px 0;
      }
    }

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      p {
        width: 72vw;
        font-family: 'Mulish', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 110%;
        margin-right: 0;
      }
    }
  }
}
